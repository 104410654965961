import SettleIntop from '@/components/settleIntop'
import SettleInFoot from '@/components/settleInFoot'
const Index = {
  name: 'FindPassword',
  components: {
    SettleInFoot,
    SettleIntop
  },
  mounted() {
    this.GetValidateCodeImg()
  },
  data() {
    return {
      isWait: false,
      active: 1,
      form: {
        userName: '',
        phoneNum: '',
        validCode: '',
        code: ''
      },
      Guid: '',
      rorm: {
        newpwd: '',
        newpwd2: '',
        userName: '',
        phoneNum: ''
      },
      rormrules: {
        newpwd: [
          { required: true, message: '请输入你的新密码', trigger: 'blur' }
        ],
        newpwd2: [
          { required: true, message: '请输入你的新密码', trigger: 'blur' },
          { validator: (rules, value, callback) => {
            if (!value) {
              callback(new Error('请再测输入你的新密码'))
            } else if (value !== this.rorm.newpwd) {
              callback(new Error('两次输入密码不正确'))
            }
            callback()
          }, trigger: ['blur', 'change'] }
        ]
      },
      rules: {
        userName: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        phoneNum: [
          { required: true, message: '请输入你的手机号', trigger: 'blur' },
          { validator: (rules, value, callback) => {
            if (!value) {
              callback(new Error('请输入手机号'))
            } else if (!this.$minCommon.checkMobile(value)) {
              callback(new Error('请输入正确的手机号'))
            }
            callback()
          }, trigger: ['blur', 'change'] }
        ],
        validCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { validator: (rules, value, callback) => {
            if (!value) {
              callback(new Error('请输入验证码'))
            } else if (value.length !== 6) {
              callback(new Error('请输入6位验证码'))
            }
            callback()
          }, trigger: ['blur', 'change'] }
        ],
        code: [
          { required: true, message: '请输入图形验证码', trigger: 'blur' },
          { validator: (rules, value, callback) => {
            if (!value) {
              callback(new Error('请输入验证码'))
            } else if (value.length !== 4) {
              callback(new Error('请输入4位图形验证码'))
            }
            callback()
          }, trigger: ['blur', 'change'] }
        ]
      },
      code_img: '',
      codeNumLast: 0
    }
  },
  methods: {
    // 重新获取图形验证码
    changeImg() {
      this.GetValidateCodeImg()
    },
    GetValidateCodeImg() {
      this.$api.GetValidateCodeImg().then(res => {
        if (res.RetCode !== '0') return this.$message.error(res.RetMsg)
        this.code_img = `data: image/jpg; base64, ${res.Message.Bytes}`
        this.Guid = res.Message.Guid
      })
    },
    next(formName) {
      // 做验证 是否进行下一步
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        this.isWait = true
        this.$api.AuthUserAcount(this.form).then(res => {
          this.isWait = false
          if (res.RetCode !== '0') {
            return this.$message.error('错误：' + res.RetMsg)
          }
          this.active = 2
        }).catch(err => {
          this.isWait = false
          return this.$message.error('错误：' + err)
        })
      })
    },
    // 获取短信验证码
    SendPhoneValidataCode() {
      if (this.codeNumLast > 0) return
      if (!this.$minCommon.checkMobile(this.form.phoneNum)) {
        this.form.phoneNum = ' '
        setTimeout(() => {
          this.form.phoneNum = ''
        }, 20)
        return
      }
      if (this.form.code.length !== 4) {
        this.form.code = ' '
        setTimeout(() => {
          this.form.code = ''
        }, 20)
        return
      }
      if (!this.Guid) {
        return this.$message.error('请重新获取图形验证码')
      }
      this.$api.SendPhoneValidataCode({
        telNumber: this.form.phoneNum,
        validateCode: this.form.code,
        validCodeGuid: this.Guid
      }).then(res => {
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.$minCommon.setCountDown((num, timer) => {
          this.codeNumLast = num
        })
      })
    },
    // 重置新密码
    UpdatePwdByMoblie(formName) {
      this.rorm.userName = this.form.userName
      this.rorm.phoneNum = this.form.phoneNum

      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        this.isWait = true
        this.$api.UpdatePwdByMoblie(this.rorm).then(res => {
          this.isWait = true
          if (res.RetCode !== '0') {
            return this.$message.error('错误：' + res.RetMsg)
          }
          this.$message.success('重置密码成功，请重新登录')
          setTimeout(() => {
            return this.$router.push('/login')
          }, 1000)
        }).catch(err => {
          this.isWait = false
          return this.$message.error('错误：' + err)
        })
      })
    }
  }
}
export default Index
