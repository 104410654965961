<template>
  <div class="settleIntop min-border-bottom flex a-center">
    <div class="image_veiw" @click="$router.push('/')" />
    <span class="border" />
    <span class="p-left-20 weight f14">
      {{ title }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'SettleIntop',
  props: {
    title: {
      type: String,
      default: '账号找回'
    }
  }
}
</script>
<style lang="scss" scoped>
.settleIntop{
    width: 100%;
    height: 70px;
    padding: 0 100px;
    .border {
        width: 1px;
        height: 50%;
        background-color: #ccc;
    }
    .image_veiw{
        width:  150px;
        height: 100%;
        background-image: url('../static/logo.png');
        background-repeat: no-repeat;
        background-position: left 57px center;
    }
}
</style>
