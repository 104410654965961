<template>
  <div class="settleInFoot">
    <!-- 页脚 -->
    <div class="FooterBox flex j-center a-center column" style="width:100%">
      <div class="foot_top control">
        <div class="left">
          <ul>
            <li>商务联系</li>
            <li class="f_28 fam1">021-61390181</li>
            <li class="f_12">周一至周五 9:30~18:30</li>
          </ul>
        </div>
        <div class="right">
          <ul>
            <li class="w70">
              <div class="title">服务中心</div>
              <a href="#">使用条款</a>
              <a href="#">隐私政策</a>
              <a href="#">免责声明</a>
              <a href="#">关于我们</a>
            </li>
            <li class="w70">
              <div class="title">帮助中心</div>
              <a href="#">企业入驻</a>
              <a href="#">新手入门</a>
              <a href="#">使用指南</a>
              <a href="#">常见问题</a>
            </li>
            <li class="w135">
              <div class="title">更多行业解决方案</div>
              <a href="#">全院库存管理系统SIMS</a>
              <a href="#">物流仓储管理系统WMS</a>
              <a href="#">冷链数据融合平台CClS</a>
              <a href="#">全院仪器服务平台ISP</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="foot" style="width:100%;">
        <div class="foot_bottom control f12">
          Copyright © 2020 上海秸瑞信息科技有限公司 版权所有 沪ICP备05009027号-1 互联网药品信息服务资格证书编号：(沪)-经营性-2020-0019
          <a style="color: #6d7288;" href="https://beian.miit.gov.cn">沪ICP备19046069号-1</a>
          <div class="f12">
            互联网药品信息服务资格证书编号：<a style="color: #6d7288;">(沪)-经营性-2020-0019</a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            ICP增值电性业务许可证书编号：<a style="color: #6d7288;">沪B2-20200588</a>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  name: 'SettleInFoot'
}
</script>
<style lang="scss" scoped>
@import '../style/loginnew.css';
.settleInFoot {
    min-width: 100%;
    width: 100%;
    max-width: max-content;
    // overflow: auto;
}
</style>
